<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <h1>{{ t("settings.header") }}</h1>
      <div class="c-header-icon-container"></div>
    </div>
  </div>
  <div id="open-picking-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("settings.header") }}</h1>
    </Teleport>
    <Teleport to="#c-navbar-shortcuts">
      <div class="c-header-icon-container"></div>
    </Teleport>
  </div>
  <div class="c-settings">
    <div>
      <Panel :header="t('settings.tax.header')" toggleable class="mb-4" id="taxPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-tax-panel">
            <TaxList />
          </div>
        </div>
      </Panel>

      <Panel :header="t('settings.payment-terms.header')" toggleable class="mb-4" id="paymentTermsPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-payment-terms-panel">
            <PaymentTermsList :paginator="false" />
          </div>
        </div>
      </Panel>

      <Panel :header="t('settings.account-plan.header')" toggleable class="mb-4" id="accountPlanPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-account-plan-panel">
            <AccountPlans></AccountPlans>
          </div>
        </div>
      </Panel>

      <Panel :header="t('settings.number-series.header')" toggleable class="mb-4" id="numberSeriesPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-number-series-panel">
            <NumberSeries></NumberSeries>
          </div>
        </div>
      </Panel>

      <Panel :header="t('settings.currency.header')" toggleable class="mb-4" id="currencyPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-currency-panel">
            <CurrencyList />
          </div>
        </div>
      </Panel>

      <Panel :header="t('settings.account-setup.header')" toggleable class="mb-4" id="accountSetupPanel">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-account-setup-panel">
            <AccountSettingsList />
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import PaymentTermsList from "@/components/payment-term/PaymentTermsList.vue";
import AccountPlans from "@/components/account-plan/AccountPlans.vue";
import NumberSeries from "@/number-series/components/NumberSeries.vue";
import CurrencyList from "@/components/currency/CurrencyList.vue";
import AccountSettingsList from "@/components/account-settings/AccountSettingsList.vue";
import { useClientStore } from "@/stores/ClientStore";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import TaxList from "@/components/tax/TaxList.vue";
import { useTaxStore } from "@/stores/TaxStore";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
const { t } = useI18n();
const { getClients } = useClientStore();
const { getAccountPlans } = useAccountPlanStore();
const { getTaxes } = useTaxStore();
const { getPaymentTerms } = usePaymentTermStore();

onMounted(() => {
  getClients();
  getAccountPlans();
  getTaxes();
  getPaymentTerms();
});
</script>

<style scoped lang="scss">
.c-settings {
  margin: var(--default-content-margin);
}

//TODO: Remove this when the issue with the card overflow is fixed
// .c-settings .card {
//   overflow: auto;
// }
</style>
